import repository from './repository'

const resource = 'purchase-requests'

export default {
  index () {
    return repository.get(resource)
  },
  store (data) {
    return repository.post(resource, data)
  },
//   update (id, data) {
//     return repository.put(`${resource}/${id}`, data)
//   },
//   edit (id) {
//     return repository.get(`${resource}/${id}/edit`)
//   },
//   detail (id) {
//     return repository.get(`${resource}/${id}/detail`)
//   },
}
