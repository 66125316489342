<template lang="pug">
  v-container
    v-row
      v-col.pb-10(cols="12")
        v-toolbar(
          color="primary"
          dark
        )
          span New Purchase Requisition
          v-spacer
          v-icon mdi-account
          v-icon mdi-plus
        v-alert(
          class="mt-2"
          type="error"
          dismissible
          v-for="(item, index) in purchReqPostErrors"
          :key="index"
        )
          span.text-uppercase {{ item.first() }}
      v-col.py-0(
        lg="3"
        offset-lg="9"
        cols="12"
      )
        v-text-field(
          label="PR ID"
          v-model="prId"
          outlined
          dense
        )
      v-col.py-0(
        lg="3"
        cols="12"
      )
        v-autocomplete(
          label="PR Status"
          v-model="statusID"
          :items="purchReqStatusList"
          :filter="autocompleteFilter(['id', 'description'])"
          item-value="id"
          outlined
          dense
        )
          template(v-slot:selection="{ item }")
            span.primary--text {{ item.id }}
            span.blue-grey--text.mx-1.text-caption {{ item.description }}
          template(v-slot:item="{ item }")
            span.primary--text {{ item.id }}
            span.blue-grey--text.mx-1.text-caption {{ item.description }}
      v-col.py-0(
        lg="3"
        cols="12"
      )
        v-text-field(
          label="Date Submitted"
          v-model="submittedDate"
          dense
          outlined
          type="date"
        )
      v-col.py-0(
        lg="3"
        cols="12"
      )
        v-text-field(
          label="Date Expected"
          v-model="expectedDate"
          dense
          outlined
          type="date"
        )
      v-col.py-0(
        lg="3"
        cols="12"
      )
        v-text-field(
          label="Amount"
          type="number"
          v-model="amount"
          outlined
          dense
        )
      v-col.py-0(
        lg="6"
        cols="12"
      )
        v-textarea(
          label="Notes"
          v-model="notes"
          dense
          no-resize
          outlined
          rows="6"
        )
      v-col.py-0(
        lg="6"
        cols="12"
      )
        v-row(no-gutters)
          v-col.py-0.ma-0(cols="12")
            v-text-field.ma-0(
              label="Date Approved"
              v-model="approvedDate"
              dense
              type="date"
              outlined
            )
          v-col.pt-0(cols="12")
            v-text-field(
              label="Approved By"
              v-model="approvedBy"
              outlined
              disabled
              dense
            )
          v-col.py-0(cols="12")
            v-text-field(
              label="Submitted By"
              v-model="submittedBy"
              outlined
              dense
            )
      v-col(cols="12")
        v-data-table(
          :items="prItemsNative"
          :headers="headers"
          single-expand
          show-expand
          hide-default-footer
          disable-pagination
          dense
        )
          template(v-slot:top)
            tr
              th(align="center") PR Items
          template(v-slot:item="{ item, isExpanded, expand }")
            tr
              td.border-right {{ item.stockId }}
              td.border-right {{ item.description }}
              td.border-right(align="right" width="100px") {{ item.quantity }}
              td.border-right(align="right") {{ item.cost.toString().formatMoney() }}
              td(align="center" width="10px")
                v-btn(
                  icon
                  :color="isExpanded ? 'yellow': 'green'"
                  @click="expand(!isExpanded)"
                )
                  v-icon mdi-pencil
          template(v-slot:body.append)
            tr
              td(colspan="4")
      v-col(
        lg="1"
        cols="12"
      )
        v-btn(
          color="primary"
          dark
          @click="storePurchaseRequisition"
          :loading="purchReqPosting"
        )
          span Save
      v-col(
        lg="1"
        cols="12"
      )
        v-btn(
          color="red"
          dark
          @click="$router.push({ name: 'customer-forecasts.table' })"
        )
          span Cancel
</template>
<style lang="scss" scoped>
  // .border-right {
  //   border-bottom: 1px lightgray solid;
  // }
  // .border-bottom {
  //   border-bottom: 1px lightgray solid;
  // }
</style>
<script>
import purchReqStatusRepository from '@/repositories/purchase-request-status.repository'
import purchReqRepository from '@/repositories/purchase-request.repository'
import { getVars, postVars, errorHandler } from '@/libs/api-helper.extra'

const inputVars = () => ({
  prId: null,
  submittedDate: null,
  statusID: null,
  expectedDate: null,
  amount: null,
  notes: null,
  approvedBy: null,
  approvedDate: null,
  submittedBy: null,
  createdBy: null,
  updatedBy: null,
})

const tableVars = () => ({
  headers: [
    { text: 'Stock ID', value: 'stockId' },
    { text: 'Description', value: 'description' },
    { text: 'Quantity', value: 'quantity' },
    { text: 'Cost Per Bag', value: 'cost' },
    { text: '', value: 'data-table-expand' },
  ],
})

const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

export default {
  name: 'CreatePurchaseRequest',
  props: {
    prItems: {
      type: Array,
      default: () => [],
    },
  },
  created () {
    this.getPurchReqStatus()
    this.prItemsNative = this.prItems
  },
  data () {
    return {
      ...inputVars(),
      ...tableVars(),
      ...getVars('purch-req-status'),
      ...postVars('purch-req'),
      prItemsNative: [],
    }
  },
  watch: {
    prItems (items) {
      this.prItemsNative = items
    },
  },
  methods: {
    getPurchReqStatus () {
      if (this.purchReqStatusGetting) return
      this.purchReqStatusGetting = true
      purchReqStatusRepository.index()
        .then(({ data }) => { this.purchReqStatusList = data })
        .catch(e => { this.purchReqStatusGetErrors = errorHandler(e) })
        .then(() => { this.purchReqStatusGetting = false })
    },
    populateInputs () {

    },
    storePurchaseRequisition () {
      if (this.purchReqPosting) return
      this.purchReqPosting = true
      this.purchReqPostErrors = []
      const data = this.getInputData()
      purchReqRepository.store(data)
        .then(() => { this.$router.push('/purchase-request') })
        .catch(e => { this.purchReqPostErrors = errorHandler(e) })
        .then(() => { this.purchReqPosting = false })
    },
    getInputData () {
      return {
        pr_id: this.prId,
        submitted_date: this.submittedDate,
        status_id: this.statusID,
        expected_date: this.expectedDate,
        amount: this.amount,
        notes: this.notes,
        approved_by: this.approvedBy,
        approved_date: this.approvedDate,
        submitted_by: this.submittedBy,
        created_by: this.createdBy,
        updated_by: this.updatedBy,
      }
    },
    autocompleteFilter (fields) {
      return (item, queryText, itemText) => {
        const filters = fields.map(e => item[e])
        const rxItem = new RegExp(filters.join(), 'i')
        const rxQueryText = new RegExp(queryText, 'i')
        return rxQueryText.test(rxItem)
      }
    },
  },
}
</script>
